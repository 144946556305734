import React from 'react';
import PropTypes from 'prop-types';
import { Form, Table, Card, Button } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';

const CoverageStep = ({ numberOfWeeks, setNumberOfWeeks, coverageMatrix, setCoverageMatrix, shifts }) => {
  const daysOfWeek = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  const CELL_WIDTH = 45;
  const WEEK_WIDTH = CELL_WIDTH * 7;
  const LABEL_WIDTH = 200;

  const cellStyle = {
    width: `${CELL_WIDTH}px`,
    minWidth: `${CELL_WIDTH}px`,
    maxWidth: `${CELL_WIDTH}px`,
    padding: 0,
    boxSizing: 'border-box',
    overflow: 'hidden'
  };

  const labelColumnStyle = {
    width: `${LABEL_WIDTH}px`,
    minWidth: `${LABEL_WIDTH}px`,
    maxWidth: `${LABEL_WIDTH}px`,
    zIndex: 2,
    left: 0,
    backgroundColor: 'var(--falcon-white)',
    position: 'sticky'
  };

  const inputStyle = {
    width: `${CELL_WIDTH}px`,
    height: '24px',
    padding: '2px',
    border: 'none',
    background: 'transparent'
  };

  // Update the useEffect to properly handle week changes
  React.useEffect(() => {
    const newMatrix = {};
    const totalDays = numberOfWeeks * 7;
    
    shifts.forEach(shift => {
      if (coverageMatrix[shift.id]) {
        // If reducing weeks, truncate the array
        if (coverageMatrix[shift.id].length > totalDays) {
          newMatrix[shift.id] = coverageMatrix[shift.id].slice(0, totalDays);
        }
        // If increasing weeks, extend the array with default values
        else if (coverageMatrix[shift.id].length < totalDays) {
          newMatrix[shift.id] = [
            ...coverageMatrix[shift.id],
            ...Array(totalDays - coverageMatrix[shift.id].length).fill(3)
          ];
        }
        // If same length, keep existing values
        else {
          newMatrix[shift.id] = [...coverageMatrix[shift.id]];
        }
      } else {
        // Initialize new shift with default values
        newMatrix[shift.id] = Array(totalDays).fill(3);
      }
    });
    
    setCoverageMatrix(newMatrix);
  }, [numberOfWeeks, shifts]);

  const handleCoverageChange = (shiftId, index, value) => {
    const newMatrix = { ...coverageMatrix };
    newMatrix[shiftId][index] = parseInt(value) || 0;
    setCoverageMatrix(newMatrix);
  };

  const handleKeyDown = (e, shiftIndex, cellIndex) => {
    const currentRow = shiftIndex;
    const currentCol = cellIndex;
    const totalCols = numberOfWeeks * 7;
    const totalRows = shifts.length;

    switch (e.key) {
      case 'ArrowRight':
        if (currentCol < totalCols - 1) {
          const nextCell = document.querySelector(
            `input[data-shift-index="${currentRow}"][data-cell-index="${currentCol + 1}"]`
          );
          nextCell?.focus();
        }
        break;
      case 'ArrowLeft':
        if (currentCol > 0) {
          const prevCell = document.querySelector(
            `input[data-shift-index="${currentRow}"][data-cell-index="${currentCol - 1}"]`
          );
          prevCell?.focus();
        }
        break;
      case 'ArrowDown':
        e.preventDefault();
        if (currentRow < totalRows - 1) {
          const cellBelow = document.querySelector(
            `input[data-shift-index="${currentRow + 1}"][data-cell-index="${currentCol}"]`
          );
          cellBelow?.focus();
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        if (currentRow > 0) {
          const cellAbove = document.querySelector(
            `input[data-shift-index="${currentRow - 1}"][data-cell-index="${currentCol}"]`
          );
          cellAbove?.focus();
        }
        break;
      default:
        break;
    }
  };

  const renderDayNumbers = (weekIndex) => {
    return daysOfWeek.map((_, dayIndex) => {
      const dayNumber = weekIndex * 7 + dayIndex + 1;
      const isWeekend = dayIndex === 5 || dayIndex === 6;
      return (
        <th 
          key={`number-${weekIndex}-${dayIndex}`}
          className={`text-center p-0 ${isWeekend ? 'bg-warning-subtle' : ''}`}
          style={{ 
            width: CELL_WIDTH,
            minWidth: CELL_WIDTH,
            maxWidth: CELL_WIDTH
          }}
        >
          <div className="fs-11 text-600">{dayNumber}</div>
        </th>
      );
    });
  };

  const renderWeekHeader = (weekIndex) => {
    return daysOfWeek.map((day, dayIndex) => {
      const isWeekend = dayIndex === 5 || dayIndex === 6;
      return (
        <th 
          key={`day-${weekIndex}-${dayIndex}`}
          className={`text-center p-1 ${isWeekend ? 'bg-warning-subtle' : ''}`}
          style={{ 
            width: CELL_WIDTH,
            minWidth: CELL_WIDTH,
            maxWidth: CELL_WIDTH
          }}
        >
          {day}
        </th>
      );
    });
  };

  return (
    <Card>
      <Card.Body>
        <Form.Group className="mb-4">
          <Form.Label>Number of Weeks:</Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              type="number"
              value={numberOfWeeks}
              onChange={e => setNumberOfWeeks(parseInt(e.target.value) || 1)}
              min={1}
              max={52}
              className="form-control-sm input-spin-none"
              style={{ width: '100px' }}
            />
            <div className="falcon-button-group ms-2">
              <Button
                variant="falcon-default"
                size="sm"
                className="px-2 border-300"
                onClick={() => setNumberOfWeeks(prev => Math.max(1, prev - 1))}
              >
                -
              </Button>
              <Button
                variant="falcon-default"
                size="sm"
                className="px-2 border-300"
                onClick={() => setNumberOfWeeks(prev => Math.min(52, prev + 1))}
              >
                +
              </Button>
            </div>
          </div>
        </Form.Group>

        <div className="position-relative" style={{ height: '300px' }}>
          <SimpleBar style={{ height: '100%', width: '100%' }}>
            <div style={{ 
              width: `${LABEL_WIDTH + (numberOfWeeks * WEEK_WIDTH)}px`,
              minWidth: `${LABEL_WIDTH + (numberOfWeeks * WEEK_WIDTH)}px`,
              overflowX: 'auto'
            }}>
              <Table 
                bordered 
                hover 
                size="sm" 
                className="mb-0" 
                style={{ 
                  tableLayout: 'fixed',
                  width: `${LABEL_WIDTH + (numberOfWeeks * WEEK_WIDTH)}px`,
                  borderCollapse: 'separate',
                  borderSpacing: 0
                }}
              >
                <colgroup>
                  <col style={{ width: `${LABEL_WIDTH}px` }} />
                  {[...Array(numberOfWeeks * 7)].map((_, index) => (
                    <col key={index} style={{ width: `${CELL_WIDTH}px` }} />
                  ))}
                </colgroup>
                
                <thead>
                  <tr>
                    <th style={labelColumnStyle}>&nbsp;</th>
                    {[...Array(numberOfWeeks)].map((_, weekIndex) => (
                      <React.Fragment key={`numbers-${weekIndex}`}>
                        {renderDayNumbers(weekIndex)}
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <th style={labelColumnStyle}>&nbsp;</th>
                    {[...Array(numberOfWeeks)].map((_, weekIndex) => (
                      <React.Fragment key={`days-${weekIndex}`}>
                        {renderWeekHeader(weekIndex)}
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {shifts.map((shift, shiftIndex) => (
                    <tr key={shift.id}>
                      <td style={labelColumnStyle} className="text-900 fw-bold text-center">
                        {shift.name}
                      </td>
                      {coverageMatrix[shift.id]?.map((value, cellIndex) => (
                        <td 
                          key={cellIndex} 
                          className={cellIndex % 7 >= 5 ? 'bg-warning-subtle' : ''} 
                          style={cellStyle}
                        >
                          <Form.Control
                            type="number"
                            min="0"
                            max="99"
                            value={value}
                            onChange={(e) => handleCoverageChange(shift.id, cellIndex, e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, shiftIndex, cellIndex)}
                            data-shift-index={shiftIndex}
                            data-cell-index={cellIndex}
                            size="sm"
                            className="text-center input-spin-none"
                            style={inputStyle}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </SimpleBar>
        </div>
      </Card.Body>
    </Card>
  );
};

CoverageStep.propTypes = {
  numberOfWeeks: PropTypes.number.isRequired,
  setNumberOfWeeks: PropTypes.func.isRequired,
  coverageMatrix: PropTypes.object.isRequired,
  setCoverageMatrix: PropTypes.func.isRequired,
  shifts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired
  })).isRequired
};

export default CoverageStep; 