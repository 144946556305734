import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { weekDays } from './WizardCommon';

const HorizonStep = ({
  weekStartsOn,
  setWeekStartsOn,
  numberOfDays,
  setNumberOfDays
}) => {
  return (
    <Row>
      <Col md={6}>
        <Form>
          <Form.Group className="mb-4">
            <Form.Label>Week starts on:</Form.Label>
            <Form.Select
              value={weekStartsOn}
              onChange={e => setWeekStartsOn(e.target.value)}
            >
              {weekDays.map(day => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group>
            <Form.Label>Number of Days:</Form.Label>
            <div className="d-flex align-items-center">
              <Form.Control
                type="number"
                value={numberOfDays}
                onChange={e => setNumberOfDays(parseInt(e.target.value))}
                min={1}
                className="form-control-sm input-spin-none"
                style={{ width: '100px' }}
              />
              <div className="falcon-button-group ms-2">
                <Button
                  variant="falcon-default"
                  size="sm"
                  className="px-2 border-300"
                  onClick={() => setNumberOfDays(prev => Math.max(1, prev - 1))}
                >
                  -
                </Button>
                <Button
                  variant="falcon-default"
                  size="sm"
                  className="px-2 border-300"
                  onClick={() => setNumberOfDays(prev => prev + 1)}
                >
                  +
                </Button>
              </div>
            </div>
          </Form.Group>
        </Form>
      </Col>
    </Row>
  );
};

HorizonStep.propTypes = {
  weekStartsOn: PropTypes.string.isRequired,
  setWeekStartsOn: PropTypes.func.isRequired,
  numberOfDays: PropTypes.number.isRequired,
  setNumberOfDays: PropTypes.func.isRequired
};

export default HorizonStep; 