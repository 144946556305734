import React from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ShiftsStep = ({ shifts, setShifts }) => {
  const addCustomShift = () => {
    const newShift = {
      id: `custom-${Date.now()}`,
      name: `Custom Shift ${shifts.filter(s => s.id.startsWith('custom-')).length + 1}`,
      label: 'Custom',
      enabled: true,
      start: '09:00',
      end: '17:00',
    };
    setShifts([...shifts, newShift]);
  };

  const deleteShift = (shiftId) => {
    setShifts(shifts.filter(shift => shift.id !== shiftId));
  };

  return (
    <Row>
      <Col md={12}>
        <div className="shifts-container">
          <Row className="g-4">
            {shifts.map((shift, index) => (
              <Col key={shift.id} md={4} lg={3}>
                <Card className="h-100">
                  <Card.Header className="d-flex align-items-center">
                    <Form.Check
                      type="checkbox"
                      checked={shift.enabled}
                      onChange={e => {
                        const updatedShifts = [...shifts];
                        updatedShifts[index].enabled = e.target.checked;
                        setShifts(updatedShifts);
                      }}
                      className="me-2"
                    />
                    <h6 className="mb-0 flex-grow-1">{shift.name}</h6>
                    <span
                      className="shift-label ms-2"
                      style={{
                        padding: '2px 8px',
                        borderRadius: '4px',
                        backgroundColor: 'var(--falcon-200)',
                        fontSize: '0.875rem'
                      }}
                    >
                      {shift.label}
                    </span>
                    {shift.id.startsWith('custom-') && (
                      <Button
                        variant="falcon-default"
                        size="sm"
                        className="ms-2 p-0 px-1"
                        onClick={() => deleteShift(shift.id)}
                      >
                        <FontAwesomeIcon icon="trash-alt" className="text-danger fs--2" />
                      </Button>
                    )}
                  </Card.Header>
                  <Card.Body>
                    {shift.id !== 'off' && (
                      <Form>
                        {shift.id.startsWith('custom-') && (
                          <Form.Group className="mb-3">
                            <Form.Label>Shift Name</Form.Label>
                            <Form.Control
                              type="text"
                              value={shift.name}
                              onChange={e => {
                                const updatedShifts = [...shifts];
                                updatedShifts[index].name = e.target.value;
                                setShifts(updatedShifts);
                              }}
                              disabled={!shift.enabled}
                            />
                          </Form.Group>
                        )}
                        <Form.Group className="mb-3">
                          <Form.Label>Start Time</Form.Label>
                          <Form.Control
                            type="time"
                            value={shift.start}
                            onChange={e => {
                              const updatedShifts = [...shifts];
                              updatedShifts[index].start = e.target.value;
                              setShifts(updatedShifts);
                            }}
                            disabled={!shift.enabled || shift.id === 'off'}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>End Time</Form.Label>
                          <Form.Control
                            type="time"
                            value={shift.end}
                            onChange={e => {
                              const updatedShifts = [...shifts];
                              updatedShifts[index].end = e.target.value;
                              setShifts(updatedShifts);
                            }}
                            disabled={!shift.enabled || shift.id === 'off'}
                          />
                        </Form.Group>
                      </Form>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            ))}
            <Col md={4} lg={3}>
              <Card 
                className="h-100 d-flex align-items-center justify-content-center bg-light cursor-pointer"
                onClick={addCustomShift}
                role="button"
              >
                <div className="text-center p-4">
                  <FontAwesomeIcon icon="plus" className="text-primary fs-1 mb-3" />
                  <h6 className="mb-0">Add Custom Shift</h6>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default ShiftsStep; 