import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HolidaysStep = ({ holidays, setHolidays }) => {
  const [selectedRegion, setSelectedRegion] = useState('');
  const [isCustom, setIsCustom] = useState(false);
  const [newHoliday, setNewHoliday] = useState({
    name: '',
    date: '',
    duration: 1
  });

  const handleRegionChange = (e) => {
    const value = e.target.value;
    setSelectedRegion(value);
    setIsCustom(value === 'Custom');
    
    if (value === 'British Columbia, Canada 2024-2025') {
      setHolidays([
        { name: 'New Year\'s Day', date: '2024-01-01', duration: 1 },
        { name: 'Family Day', date: '2024-02-19', duration: 1 },
        { name: 'Good Friday', date: '2024-03-29', duration: 1 },
        { name: 'Victoria Day', date: '2024-05-20', duration: 1 },
        { name: 'Canada Day', date: '2024-07-01', duration: 1 },
        { name: 'BC Day', date: '2024-08-05', duration: 1 },
        { name: 'Labour Day', date: '2024-09-02', duration: 1 },
        { name: 'National Day for Truth and Reconciliation', date: '2024-09-30', duration: 1 },
        { name: 'Thanksgiving Day', date: '2024-10-14', duration: 1 },
        { name: 'Remembrance Day', date: '2024-11-11', duration: 1 },
        { name: 'Christmas Day', date: '2024-12-25', duration: 1 },
        { name: 'Boxing Day', date: '2024-12-26', duration: 1 }
      ]);
    } else if (value === 'Custom') {
      setHolidays([]);
    }
  };

  const handleAddHoliday = () => {
    if (newHoliday.name && newHoliday.date) {
      setHolidays([...holidays, { ...newHoliday }]);
      setNewHoliday({ name: '', date: '', duration: 1 });
    }
  };

  const handleRemoveHoliday = (index) => {
    const updatedHolidays = holidays.filter((_, i) => i !== index);
    setHolidays(updatedHolidays);
  };

  return (
    <Row>
      <Col md={12}>
        <Card>
          <Card.Body>
            <Form>
              <Form.Group className="mb-4">
                <Form.Check
                  type="radio"
                  id="bc-canada"
                  label="British Columbia, Canada 2024-2025"
                  name="holidayRegion"
                  value="British Columbia, Canada 2024-2025"
                  checked={selectedRegion === 'British Columbia, Canada 2024-2025'}
                  onChange={handleRegionChange}
                  className="mb-2"
                />
                <Form.Check
                  type="radio"
                  id="custom"
                  label="Custom"
                  name="holidayRegion"
                  value="Custom"
                  checked={selectedRegion === 'Custom'}
                  onChange={handleRegionChange}
                />
              </Form.Group>

              {selectedRegion && (
                <div className="holidays-table">
                  <Table responsive bordered hover>
                    <thead>
                      <tr>
                        <th>Holiday Name</th>
                        <th>Date</th>
                        <th>Duration (Days)</th>
                        {isCustom && <th>Actions</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {holidays.map((holiday, index) => (
                        <tr key={index}>
                          <td>{holiday.name}</td>
                          <td>{holiday.date}</td>
                          <td>{holiday.duration}</td>
                          {isCustom && (
                            <td>
                              <Button
                                variant="falcon-danger"
                                size="sm"
                                onClick={() => handleRemoveHoliday(index)}
                              >
                                <FontAwesomeIcon icon="trash-alt" />
                              </Button>
                            </td>
                          )}
                        </tr>
                      ))}
                      {isCustom && (
                        <tr>
                          <td>
                            <Form.Control
                              type="text"
                              placeholder="Holiday name"
                              value={newHoliday.name}
                              onChange={(e) => setNewHoliday({ ...newHoliday, name: e.target.value })}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="date"
                              value={newHoliday.date}
                              onChange={(e) => setNewHoliday({ ...newHoliday, date: e.target.value })}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              min="1"
                              value={newHoliday.duration}
                              onChange={(e) => setNewHoliday({ ...newHoliday, duration: parseInt(e.target.value) })}
                            />
                          </td>
                          <td>
                            <Button
                              variant="falcon-primary"
                              size="sm"
                              onClick={handleAddHoliday}
                            >
                              <FontAwesomeIcon icon="plus" />
                            </Button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              )}
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

HolidaysStep.propTypes = {
  holidays: PropTypes.array.isRequired,
  setHolidays: PropTypes.func.isRequired
};

export default HolidaysStep; 