import paths, { rootPaths } from './paths';

export const adminAreaRoutes = {
  label: 'Admin Area',
  children: [
    {
      name: 'Request a Rotation',
      active: true,
      icon: 'plus',
      to: rootPaths.root,
      exact: true
    }
  ]
};

export const userAreaRoutes = {
  label: 'User Area',
  children: [
    {
      name: 'Calendar',
      icon: 'calendar-alt',
      to: paths.calendar,
      active: true
    }
  ]
};

export default [adminAreaRoutes, userAreaRoutes];
