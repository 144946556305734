import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import classNames from 'classnames';

const schema = yup.object({
  description: yup.string().required('Description is required'),
  example: yup.string().required('Example is required')
}).required();

const CustomRuleDialog = ({ show, onHide, onAdd }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = data => {
    onAdd(data);
    reset();
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Custom Rule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <p className="text-muted">
            Please provide a complete explanation of the rule you want to add, and provide examples to
            demonstrate acceptable and unacceptable scenarios.
          </p>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter rule description"
              {...register('description')}
              className={classNames({ 'is-invalid': errors.description })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.description?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Example</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter example scenarios"
              {...register('example')}
              className={classNames({ 'is-invalid': errors.example })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.example?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button variant="falcon-default" onClick={onHide} type="button" className="me-2">
              Cancel
            </Button>
            <Button variant="falcon-primary" type="submit">
              Add
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

CustomRuleDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired
};

export default CustomRuleDialog; 