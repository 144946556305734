import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import lineRotationImage from '../../../../assets/img/app/line-rotation-format.png';
import blockRotationImage from '../../../../assets/img/app/block-rotation-format.png';

const FormatStep = ({ selectedRotation, setSelectedRotation }) => {
  const rotationOptions = [
    { value: 'line', label: 'Line Rotation' },
    { value: 'block', label: 'Block Rotation' }
  ];

  const getRotationImage = () => {
    if (selectedRotation === 'line') {
      const lineStyle = {
        width: 'auto',
        height: '200px',
        transform: 'scale(0.8)'
      };
      
      return <img 
        src={lineRotationImage} 
        alt="Line rotation format"
        style={lineStyle}
        className="rotation-format-image"
      />;
    } else if (selectedRotation === 'block') {
      const blockStyle = {
        width: '400px',
        height: 'auto',
        transform: 'scale(0.8)'
      };
      
      return <img 
        src={blockRotationImage} 
        alt="Block rotation format"
        style={blockStyle}
        className="rotation-format-image"
      />;
    }
    return null;
  };

  return (
    <SimpleBar style={{ height: '100%' }}>
      <div className="h-100 p-3">
        <div className="d-flex">
          <div className="me-3" style={{ width: '25%' }}>
            <Card>
              <Card.Header className="bg-light">
                <h6 className="mb-0">Rotation Format</h6>
              </Card.Header>
              <Card.Body>
                {rotationOptions.map(option => (
                  <Form.Check
                    key={option.value}
                    type="radio"
                    id={option.value}
                    label={option.label}
                    checked={selectedRotation === option.value}
                    onChange={() => setSelectedRotation(option.value)}
                    className="mb-2"
                  />
                ))}
              </Card.Body>
            </Card>
          </div>
          <div style={{ width: '75%' }}>
            <Card>
              <Card.Header className="bg-light">
                <h6 className="mb-0">Explanations and examples</h6>
              </Card.Header>
              <Card.Body>
                {getRotationImage() ? (
                  <div className="rotation-image-container">
                    <div className="rotation-diagram">
                      {getRotationImage()}
                    </div>
                  </div>
                ) : (
                  <div className="text-center">
                    <p className="text-500 fs--1 mb-0">
                      Select a rotation format to view the pattern
                    </p>
                  </div>
                )}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </SimpleBar>
  );
};

FormatStep.propTypes = {
  selectedRotation: PropTypes.string.isRequired,
  setSelectedRotation: PropTypes.func.isRequired
};

export default FormatStep;
